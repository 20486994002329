import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { Header, Footer } from "."
import { theme } from "../styles/theme"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Header />
        <div>{children}</div>
        <Footer />
      </Root>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  overflow-x: hidden;
  padding-top: 100px;
  width: 100%;

  /** media queries */

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-top: 60px;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
      props.theme.breakpoints.md}) {
    padding-top: 70px;
  }
`
