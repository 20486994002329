import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { ButtonBaseProps } from "./types"
import { ButtonBase } from "./"

const Button = ({ label, filled = true, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <ButtonBase label={label} filled={filled} />
    </Wrapper>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  ...ButtonBaseProps,
}

export default Button

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  width: max-content;
`
