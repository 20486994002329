import React, { useState, useEffect, useRef, useCallback } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import logo from "../images/img-logo.svg"
import { useLocation } from "@reach/router"

const Header = () => {
  const navLinks = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "About",
      url: "/about/",
    },
    {
      text: "Landowners",
      url: "/landowners/",
    },
    {
      text: "Contact",
      url: "/contact/",
    },
  ]

  const navBar = useRef()

  const updateOpacity = () => {
    if (navBar.current) {
      if (window.scrollY < 100) {
        navBar.current.style.backgroundColor = `rgba(255, 255, 255, ${
          window.scrollY / 100
        })`
      } else {
        navBar.current.style.backgroundColor = "#ffffff"
      }
    }
  }

  const location = useLocation()
  const path = location.pathname
  const activeIndex = navLinks.findIndex((link) => link.url.match(path))

  const updateMarker = useCallback(() => {
    const marker = document.getElementById("marker")
    const links = document.querySelectorAll(".navLink")
    if (marker && activeIndex >= 0) {
      marker.style.left = `${links[activeIndex].offsetLeft}px`
      marker.style.width = `${links[activeIndex].offsetWidth}px`
    }
  }, [activeIndex])

  useEffect(() => {
    window.addEventListener("scroll", updateOpacity)
    window.addEventListener("resize", updateMarker)
    return () => {
      window.removeEventListener("scroll", updateOpacity)
      window.addEventListener("resize", updateMarker)
    }
  })

  useEffect(() => {
    updateMarker()
  }, [activeIndex, updateMarker])

  useEffect(() => {
    updateOpacity()
  }, [])

  const [menuShowing, setMenuShowing] = useState(false)

  const toggleMenu = () => {
    setMenuShowing(!menuShowing)
  }

  useEffect(() => {
    const prev = document.querySelector("#active.mobileMenuItem")
    const links = document.querySelectorAll(".mobileMenuItem")
    if (links && activeIndex >= 0) {
      prev?.setAttribute("id", "")
      links[activeIndex].setAttribute("id", "active")
    }
    setMenuShowing(false)
  }, [activeIndex])

  return (
    <Wrapper>
      <div ref={navBar} className="topBar">
        <Section>
          <Link to="/">
            <Logo src={logo} />
          </Link>
        </Section>
        <Section className="fullMenu">
          <>
            <div id="marker" />
            <div>
              {navLinks.map((link, index) => (
                <NavLink key={index} to={link.url} className="navLink">
                  {link.text}
                </NavLink>
              ))}
            </div>
          </>
        </Section>
        <Section className="mobileMenu">
          <MenuButton
            className={`${menuShowing ? "closeButton" : "openButton"}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </MenuButton>
        </Section>
      </div>
      <MenuBody className={`mobileMenu ${menuShowing ? "open" : ""}`}>
        {navLinks.map((link, index) => (
          <NavLink key={index} to={link.url} className="mobileMenuItem">
            {link.text}
          </NavLink>
        ))}
      </MenuBody>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  left: 0;
  margin: 0;
  max-width: 100vw;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  z-index: 100;

  .fullMenu {
    position: relative;
  }

  .topBar {
    display: flex;
    height: 100px;
    justify-content: space-between;
    margin: 0;
    padding: 0 60px 0 30px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      height: 60px;
      padding: 0 25px 0 20px;
    }

    @media screen and (min-width: ${(props) =>
        props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
        props.theme.breakpoints.md}) {
      height: 70px;
      padding: 0 25px 0 20px;
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
      background-color: #fff !important;
    }
  }
`

const Section = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;

  &.mobileMenu {
    display: none;
  }

  #marker {
    background-color: #d1232a;
    border-radius: 0 0 45px 45px;
    height: 4px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 0px;
    will-change: transform;
    transition: all 0.5s ease;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    &.fullMenu {
      display: none;
    }

    &.mobileMenu {
      display: inline-flex;
    }
  }
`

const Logo = styled.img`
  cursor: pointer;
  height: 78px;
  margin: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 38px;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
      props.theme.breakpoints.md}) {
    height: 50px;
  }
`

const NavLink = styled(Link)`
  color: #212227;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 24px;
  text-decoration: none;
  text-transform: uppercase;

  &.active {
    font-weight: 600;
  }

  &.mobileMenuItem {
    align-items: center;
    background-color: #31323b;
    color: #fff;
    display: flex;
    height: 70px;
    margin: 0 0 1px 0;
    padding: 20px;

    &#active {
      background-color: #d1232a;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
      props.theme.breakpoints.md}) {
    margin: 0 16px;
  }
`

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0px;
  padding-bottom: 4px;
  text-align: right;
  z-index: 11;

  > span {
    background-color: #31323b;
    color: #30323c;
    display: block;
    height: 2px;
    margin: 1px 0px 1px auto;
    width: 16px;

    &:nth-child(2) {
      opacity: 0;
    }

    &:last-child {
      width: 12px;
    }
  }

  &.openButton {
    span {
      transform-origin: 2px 0px;
      transform: rotate(0deg) translate(0px, 0px);
      transition: background-color 0.5s, opacity 0.5s, transform 0.5s,
        width 0.5s;
    }

    span:first-child {
      transform-origin: 25% -50%;
    }

    span:last-child {
      transform-origin: 0% 100%;
    }
  }

  &.closeButton {
    span {
      transition: background-color 0.5s, opacity 0.5s, transform 0.5s,
        width 0.5s;
    }

    span:first-child {
      transform-origin: 25% -50%;
      transform: rotate(45deg) translate(2px, 3px);
    }

    span:last-child {
      width: 16px;
      transform-origin: 0% 100%;
      transform: rotate(-45deg) translate(-4px, 3px);
    }
  }
`

const MenuBody = styled.div`
  background-color: #212227;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  transform: translateY(-600px);
  transition: transform 0.8s ease-in-out;
  width: 100%;
  z-index: 98;

  &.open {
    transform: translateY(60px);
    transition: transform 0.8s ease-out;
  }

  @media screen and (min-width: ${(props) => props.theme.minBreakpoints.sm}) {
    display: none;
  }
`
