import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import arrowIconNoFill from "../images/icn-arrow-right-no-fill.svg"

const StyledLinkBase = ({ label, size = "large" }) => {
  return (
    <Wrapper size={size}>
      <span>{label}</span>
      <img src={arrowIconNoFill} alt="Right arrow" />
    </Wrapper>
  )
}

StyledLinkBase.propTypes = {
  label: PropTypes.string,
  size: "large" | "small" | "none",
}

export default StyledLinkBase

const Wrapper = styled.div`
  align-items: center;
  color: #d1232a;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  justify-content: space-between;
  line-height: 27px;
  margin: 0;
  transition: 0.2s;
  width: max-content;

  &:hover {
    img {
      transform: translateX(10px);
    }
  }

  span {
    margin-top: 2px;
  }

  img {
    display: ${(props) => props.size === "none" && "none"};
    transition: 0.2s;
    width: 46px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 13px;
    margin: -8px 0 -8px 0;

    img {
      width: ${(props) => (props.size === "small" ? "36px" : "46px")};
    }
  }
`
