import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import logoGrayscale from "../images/img-logo-grayscale.svg"
import facebookIcon from "../images/icn-so-facebook.svg"
import twitterIcon from "../images/icn-so-twitter.svg"
import linkedInIcon from "../images/icn-so-linkedin.svg"

const Footer = () => {
  return (
    <Wrapper>
      <Content>
        <Section className="logoContainer">
          <Link to="/">
            <Logo src={logoGrayscale} />
          </Link>
        </Section>
        <Section>
          <p className="font-style-5">Contact</p>
          <div>
            <ul>
              <li>
                <a href="tel:+27217901392">T: +27 (0) 21 790 1392</a>
              </li>
              <li>
                <a href="mailto:info@red-cap.co.za">E: info@red-cap.co.za</a>
              </li>
            </ul>
          </div>
        </Section>
        <Section>
          <p className="font-style-5">Address</p>
          <div>
            Cape Town office Unit B2, Mainstream Centre, Princess Street, Hout
            Bay, Cape Town
          </div>
        </Section>
        <Section>
          <p className="font-style-5">Connect</p>
          <div className="socialMedia">
            <a href="https://www.facebook.com/RedCapEnergy" target="blank">
              <img src={facebookIcon} alt="Link to RedCap's Facebook account" />
            </a>
            <a href="https://twitter.com/RedCapEnergy" target="blank">
              <img src={twitterIcon} alt="Link to RedCap's Twitter account" />
            </a>
            <a
              href="https://za.linkedin.com/company/red-cap-energy"
              target="blank"
            >
              <img src={linkedInIcon} alt="Link to RedCap's LinkedIn account" />
            </a>
          </div>
        </Section>
        <Section className="slogan">
          <div>Leading South African developer in renewable energy</div>
        </Section>
        <Section className="copyright">
          <div className="legal">Copyright © 2021 Red Cap Energy</div>
        </Section>
        <Section>
          <div className="legal popia">
            <a href="/popia-manual.pdf" download>
              POPIA
            </a>
          </div>
        </Section>
      </Content>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  align-self: flex-end;
  background-color: #31323b;
  bottom: 0;
  display: flex;
  margin: auto 0 0 0;
  width: 100vw;
`

const Content = styled.div`
  color: #fff;
  display: grid;
  flex-wrap: wrap;
  gap: 80px 90px;
  grid-template-columns: 1fr max-content 1fr 1fr;
  margin: 0 auto;
  max-width: ${(props) => props.theme.breakpoints.xxl};
  padding: 110px 100px 50px 100px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 20px 90px;
    grid-template-columns: 1fr;

    .slogan {
      grid-row: 2;
    }

    .copyright {
      grid-row: 8;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 10% 6% 8% 6%;
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.lg}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    gap: 60px;
    grid-template-columns: repeat(3, 1fr);
    padding: 110px 80px 50px 80px;

    .logoContainer {
      grid-column: 1 / 4;
    }
  }
`

const Section = styled.div`
  > .font-style-5 {
    color: #fff;
    margin: 14px 0 16px 0;
  }

  > div {
    font-size: 14px;
    font-weight: 500;

    &.socialMedia {
      display: grid;
      grid-template-columns: repeat(3, minmax(40px, 82px));
      flex-wrap: wrap;

      a {
        max-width: 60px;
        width: 80%;

        &:active {
          background-color: transparent !important;
        }
      }

      img {
        background-color: #272730;
        border-radius: 100%;
        width: 100%;

        &:hover {
          background-color: #1f1f29;
        }
      }
    }

    &.legal {
      opacity: 0.4;
    }

    &.popia {
      font-size: 17px;
      font-weight: 600;
    }

    ul {
      list-style: none;
      margin: 0;

      li {
        font-size: 15px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
`

const Logo = styled.img`
  margin: calc(3vh - 0.8vw) 0 0 0;
  max-width: 230px;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.minBreakpoints.xxl}) {
    margin: 0;
  }
`
