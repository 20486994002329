import React from "react"
import styled from "styled-components"

import arrowIconFilled from "../images/icn-arrow-right-filled.svg"
import arrowIconShadowed from "../images/icn-arrow-right-shadowed.svg"

import { ButtonBaseProps } from "./types"

const ButtonBase = ({ label, filled = true }) => {
  return (
    <Wrapper className={filled ? "filled" : "notFilled"} filled={filled}>
      <span>{label}</span>
      {filled ? (
        <img src={arrowIconFilled} alt="Right arrow" />
      ) : (
        <img src={arrowIconShadowed} alt="Right arrow" />
      )}
    </Wrapper>
  )
}

ButtonBase.propTypes = ButtonBaseProps

export default ButtonBase

const Wrapper = styled.div`
  align-items: center;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  transition: 0.2s;
  width: max-content;

  &:hover {
    color: #d1232a;
  }

  span {
    margin-bottom: 4px;
    margin-right: 12px;
  }

  img {
    transform: translate3d(0, ${(props) => (props.filled ? 0 : "10px")}, 0);
    transition: 0.2s;
    width: 46px;
  }

  &.filled {
    background-color: #d1232a;
    color: #ffffff;
    box-shadow: 0 25px 16px -10px rgba(209, 35, 42, 0.37);
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    min-width: 195px;
    padding: 7px 7px 7px 28px;

    &:hover {
      background-color: #ffffff;
      box-shadow: 0 25px 16px -10px rgba(209, 35, 42, 0.37), 0 0 0 2px #d1232a;
      color: #d1232a;
    }
  }

  &.notFilled {
    background-color: transparent;
    font-family: "Gilroy", var(--fontFamily-sans);
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 0;

    &:hover {
      img {
        transform: translate3d(10px, 10px, 0);
      }
    }
  }
`
