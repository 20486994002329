import PropTypes from "prop-types"

export const ButtonBaseProps = {
  label: PropTypes.string,
  filled: PropTypes.bool,
}

export const StyledLinkProps = {
  label: PropTypes.string,
  href: PropTypes.bool,
  size: "large" | "small" | "none",
}
