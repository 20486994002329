import DefaultTheme from "styled-components"

export const theme = {
  ...DefaultTheme,
  breakpoints: {
    xs: "480px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1440px",
  },
  minBreakpoints: {
    xs: "481px",
    sm: "577px",
    md: "769px",
    lg: "993px",
    xl: "1201px",
    xxl: "1441px",
  },
}
