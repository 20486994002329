import React from "react"
import PropTypes from "prop-types"
import { Section } from "."
import styled from "styled-components"

const Cover = ({ children, className = "" }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

Cover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Cover

const Wrapper = styled(Section)`
  &.contentContainer {
    padding: 0;
  }

  &.banner {
    .textContainer {
      background-color: #f3f3f3;
    }
  }

  .textContainer {
    height: max-content;
    margin: -100px 0 auto auto;
    padding: 215px 100px 100px 320px;
    position: absolute;
    right: 0;
    width: max-content;

    .content {
      width: 500px;

      .font-style-3 {
        font-weight: 400;
        margin-bottom: 50px;
        margin-top: 40px;
      }

      p {
        color: #424349;
      }
    }
  }

  .imageContainer {
    padding-top: 20px;
    width: 720px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    .textContainer {
      padding: 25px 20px 38px 20px;
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    .textContainer {
      padding: 40px 50px 80px 50px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    display: flex;
    flex-direction: column-reverse;

    .textContainer {
      margin: 0;
      position: relative;
      width: 100%;

      .content {
        margin: 0 auto;
        width: 100%;
      }
    }

    .imageContainer {
      padding-top: 0;
      width: 100%;

      .gatsby-image-wrapper {
        height: auto;
        width: inherit;
      }
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.xl}) and (max-width: ${(props) =>
      props.theme.breakpoints.xxl}) {
    .textContainer {
      padding: 215px 6vw 100px 24vw;
    }

    .imageContainer {
      max-width: 720px;
      width: 50%;
    }
  }
`
