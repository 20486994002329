import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Button, Section } from "."
import icnQuote from "../images/landowners/icn-quote.svg"

const Quotes = ({ quotes }) => {
  const [tab, setTab] = useState(0)
  const maxIndex = quotes.length - 1

  const nextQuote = useCallback(() => {
    if (tab < maxIndex) {
      setTab(tab + 1)
    } else {
      setTab(0)
    }
  }, [tab, maxIndex])

  const generateQuote = useCallback(() => {
    const quote = quotes[tab]

    return quote ? (
      <Wrapper dark className="quotes">
        <div className="textContainer">
          <div className="text">
            <p className="font-style-5 speaker">{quote.speaker}</p>
            <p className="occupation">{quote.occupation}</p>
            <div>
              <img
                className="quoteIcon"
                src={icnQuote}
                alt="Opening quotation mark"
              />
              <p className="quote">{quote.quote}</p>
            </div>
          </div>
          <Button label="NEXT" filled={false} onClick={nextQuote} />
        </div>
        <div className="imageContainer">
          <img src={quote.imagePath} alt="" />
        </div>
      </Wrapper>
    ) : null
  }, [tab, quotes, nextQuote])

  return generateQuote()
}

Quotes.propTypes = {
  quotes: PropTypes.array,
}

export default Quotes

const Wrapper = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.quotes {
    padding-top: 0;
    margin-top: 245px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 100px auto 130px auto;
    max-width: 370px;

    .speaker,
    .occupation {
      margin: 0;
    }

    .occupation {
      font-family: "Gilroy", var(--fontFamily-sans);
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    > div {
      position: relative;
    }

    .quoteIcon {
      height: auto;
      left: -85px;
      position: absolute;
      width: 36px;
    }

    .quote {
      margin: 35px 0 50px 0;
      opacity: 0.8;
    }
  }

  .imageContainer {
    transform: translateY(-110px);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    &.quotes {
      margin-top: 145px;

      .textContainer .quoteIcon {
        left: -75px;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    row-gap: 30px;

    &.contentContainer.quotes {
      margin-top: 10px;

      .textContainer {
        margin: 70px auto 100px auto;

        .text {
          min-height: 310px;
        }
      }

      .imageContainer {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    &.contentContainer.quotes {
      margin-left: 50px;

      .textContainer .quoteIcon {
        left: -60px;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    .textContainer {
      max-width: 275px;
    }
  }
`
