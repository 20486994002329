import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Section = ({ children, className = "", dark = false }) => {
  return (
    <Wrapper dark={dark}>
      <div className={`contentContainer ${className}`}>{children}</div>
    </Wrapper>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dark: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

export default Section

const Wrapper = styled.div`
  background-color: ${(props) => (props.dark ? "#f3f3f3" : "transparent")};
  margin: 0 auto;
  width: 100%;

  .contentContainer {
    margin: 0;
    max-width: ${(props) => props.theme.breakpoints.xxl};
    padding: 0 100px;
    position: relative;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding: 0 20px;
    }

    @media screen and (min-width: ${(props) =>
        props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
        props.theme.breakpoints.xl}) {
      padding: 0 50px;
    }

    @media screen and (min-width: ${(props) =>
        props.theme.minBreakpoints.xxl}) {
      margin: 0 auto;
    }
  }
`
