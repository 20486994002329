import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { ButtonBaseProps } from "./types"
import { ButtonBase } from "./"

const LinkButton = ({ label, filled = true, href }) => {
  return (
    <Wrapper href={href}>
      <ButtonBase label={label} filled={filled} />
    </Wrapper>
  )
}

LinkButton.propTypes = {
  href: PropTypes.string,
  ...ButtonBaseProps,
}

export default LinkButton

const Wrapper = styled.a`
  display: inline-block;
  width: max-content;
`
