import React from "react"

import { StyledLinkProps } from "./types"
import { StyledLinkBase } from "."

const ExternalStyledLink = ({ label, href }) => {
  return (
    <a href={href} target="blank">
      <StyledLinkBase label={label} size="none" />
    </a>
  )
}

ExternalStyledLink.propTypes = {
  ...StyledLinkProps,
}

export default ExternalStyledLink
