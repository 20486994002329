import React from "react"
import { Link } from "gatsby"

import { StyledLinkProps } from "./types"
import { StyledLinkBase } from "."

const InternalStyledLink = ({ label, href, size = "large" }) => {
  return (
    <Link href={href}>
      <StyledLinkBase label={label} size={size} />
    </Link>
  )
}

InternalStyledLink.propTypes = {
  ...StyledLinkProps,
}

export default InternalStyledLink
